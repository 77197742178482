@font-face {
    font-family: "Horizontal-Regular";
    src: url("./fonts/Horizontal-Regular.woff") format("woff");
}

@font-face {
    font-family: "Horizontal-Regular";
    src: url("./fonts/Horizontal-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "TonalGrotesk-Regular";
    src: url("./fonts/TonalGrotesk-Regular.woff") format("woff");
}

@font-face {
    font-family: "TonalGrotesk-Regular";
    src: url("./fonts/TonalGrotesk-Regular.woff2") format("woff2");
}
  

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'TonalGrotesk-Regular', system-ui, sans-serif;
    }
}

