* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100dvh;
}

p {
  letter-spacing: 0.075em;
}



.bg-gradient {
  background: linear-gradient(180deg, rgba(0,0,0,1) 33%, rgba(125,126,129,1) 50%, rgba(0,0,0,1) 66.667%);
}

.bg-gradient-big {
  background: linear-gradient(180deg, rgba(0,0,0,1) 10%, rgba(125,126,129,1) 50%, rgba(0,0,0,1) 90%);
}

.video-react {
  background-color: transparent;
}

.video-react .video-react-video {
  position: relative;
  height: auto;
  width: auto;
  top: auto;
  left: auto;
  object-fit: contain;
  object-position: 50% 50%;
}

.video-react .video-react-poster {
  background-size: cover;
  background-color: transparent;
}
.video-react .video-react-big-play-button {
  border: 0;
  background-color: transparent;
  display: none;
}

.video-react .video-react-loading-spinner {
  border-color: #ffffff !important;
}
